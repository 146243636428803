(function() {
    'use strict';

    angular
        .module('elogbooks.common.actions', []);
})();

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbActionRouting() {
    var self = this,
        config = {},
        $stateProvider,
        priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_action'};

    this.addRoutes = addRoutes;

    function getView(view, viewObject) {
        var result = {};
        result[view] = viewObject;

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getMultipleViews(views) {
        var result = {};

        angular.forEach(views, function(view, $index) {
            result[view.name] = view.value;
        });

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getLabel(translationKey) {
        return { label: '{{ ::"' + translationKey + '" | translate }}' };
    }

    /**
     * Usage:
     *     elbActionRouting().addRoutes(
     *         $stateProvider,
     *         'dashboard.user.actions',
     *         true || false
     *     );
     *
     * @param {$stateProvider} _$stateProvider
     * @param {String} baseRoute
     * @param {Boolean} isBookable
     */
    function addRoutes(_$stateProvider, baseRoute, isBookable) {

        config = {
            "isBookable": isBookable,
            "baseRoute": baseRoute,
            "baseView": '@' + baseRoute + '.manage-actions',
            "baseBookViewLeft": 'elogbooks-left@' + baseRoute + '.manage-actions',
            "baseBookViewRight": 'elogbooks-right@' + baseRoute + '.manage-actions'
        };

        if (!isBookable) {
            config.baseBookViewLeft = config.baseView;
            config.baseBookViewRight = config.baseView;
        }

        $stateProvider = _$stateProvider;

        registerRoutes();
        registerRoutesAdd();
        registerRoutesDetails();
        registerRoutesDetailsJobs();
        registerRoutesDetailsQuoteRequests();
        registerRoutesDetailsWorkflow();
        registerRoutesDetailsSiteNotes();
        registerRoutesDetailsFiles();
        registerRoutesImport();
    }

    function registerRoutes() {
        $stateProvider
            .state(config.baseRoute, {
                url: '/actions',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    label: '{{ ::"ACTIONS" | translate }}'
                }
            })
            .state(config.baseRoute + '.manage-actions', {
                url: '/manage-actions',
                abstract: true,
                parent:  config.baseRoute,
                views: getView('elogbooks-center@' + config.baseRoute, {
                    templateUrl: '/modules/common/actions/actions.html',
                    controller: 'CommonActionsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteResource: function (selectedSiteResponse) {
                        return selectedSiteResponse;
                    },
                    sectionResource: function (user, userManager) {
                        return {
                            canImport: !config.baseRoute.includes('sites') && userManager.hasPermission('site_permission_action_user_imports', user),
                            listSref: config.baseRoute +'.manage-actions.list',
                            importSref: config.baseRoute +'.manage-actions.imports.list',
                            currentView: 'elogbooks-right@'+ config.baseRoute +'.manage-actions'
                        };
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.manage-actions.list', {
                url: '/list?actionsPage&actionsLimit&actionsOrder&actionsId&actionsStatus&actionsDateDueStart&actionsDateDueEnd&actionsPriorityName&actionTypes&actionsExternalSystem&coreClient&actionsRemedial&siteStatus&actionSummary&actionsCreatedAtStart&actionsCreatedAtEnd',
                parent: config.baseRoute +'.manage-actions',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/actions/manage/list/actions-list.html',
                    controller: 'CommonActionsListController',
                    controllerAs: 'vm'
                }),
                params: {
                    actionsPage: '1',
                    actionsLimit: '30',
                    actionsOrder: '-id',
                    actionsId: null,
                    actionsStatus: {array: true},
                    actionTypes: {array: true},
                    actionsDateDueStart: null,
                    actionsDateDueEnd: null,
                    actionsPriorityName: null,
                    actionsExternalSystem: {array: true},
                    actionsSystemTypes: null,
                    fileOrder: '-id',
                    coreClient: null,
                    isRemedial: null,
                    actionsRemedial: null,
                    siteStatus: 'active',
                    sourceJob: null,
                    sourceJobAsset: null,
                    sourceJobSubasset: null,
                    actionSummary: null,
                    actionsCreatedAtStart: null,
                    actionsCreatedAtEnd: null
                },
                resolve: {
                    actionTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('actiontypes'),  {actionTypesForFilters: false}, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    actionCollectionResponse: function(paramsService, apiClient, user, $stateParams, selectedSiteResponse, siteResponse, actionTypeCollectionResponse, lodash) {
                        delete paramsService.status;
                        var actionParams = {
                            order: paramsService.actionsOrder,
                            id: paramsService.actionsId,
                            'actionTypes[]': paramsService.actionTypes,
                            'status[]': !paramsService.actionsStatus ? ['open'] : paramsService.actionsStatus,
                            dateDueStart: paramsService.actionsDateDueStart ? new Date(paramsService.actionsDateDueStart).toISOString() : null,
                            dateDueEnd: paramsService.actionsDateDueEnd ? new Date(paramsService.actionsDateDueEnd).toISOString() : null,
                            priorityName: paramsService.actionsPriorityName,
                            actionTypes: paramsService.actionTypes,
                            'externalSystem[]': paramsService.actionsExternalSystem,
                            coreClient: paramsService.coreClient,
                            remedial: paramsService.actionsRemedial,
                            siteStatus:  paramsService.siteStatus,
                            actionSummary: paramsService.actionSummary,
                            actionsCreatedAtStart: paramsService.actionsCreatedAtStart ? new Date(paramsService.actionsCreatedAtStart).toISOString() : null,
                            actionsCreatedAtEnd: paramsService.actionsCreatedAtEnd ? new Date(paramsService.actionsCreatedAtEnd).toISOString() : null
                        };

                        if (paramsService.actionsSystemTypes && paramsService.actionsSystemTypes.length > 0) {
                            if (typeof paramsService.actionsSystemTypes === 'undefined') {
                                paramsService.actionsSystemTypes = [];
                            }

                            var actionsType = [];

                            for (var actionsSystemType in paramsService.actionsSystemTypes) {
                                for (var actionType in actionTypeCollectionResponse.actiontypes) {
                                    if (actionTypeCollectionResponse.actiontypes[actionType].systemType === paramsService.actionsSystemTypes[actionsSystemType]) {
                                        actionsType.push(actionTypeCollectionResponse.actiontypes[actionType].id.toString());
                                    }
                                }
                            }

                            paramsService['actionTypes[]'] = actionsType;
                            paramsService['actionTypes'] = actionsType;

                            // must also be set in stateParams to pre-select the filter on the next page
                            $stateParams['actionTypes[]'] = actionsType;
                            $stateParams['actionTypes'] = actionsType;

                            // clear actionsSystemTypes, so the actionTypes do not get re-added to
                            paramsService.actionsSystemTypes = [];
                            $stateParams.actionsSystemTypes = [];
                        }

                        if (selectedSiteResponse) {
                            paramsService.site = selectedSiteResponse.id;
                        }

                        var params = angular.extend({}, actionParams, paramsService);
                        params.id = paramsService.actionsId;
                        params.page = paramsService.actionsPage;
                        params.limit = paramsService.actionsLimit;

                        if (siteResponse.id !== undefined) {
                            params.site = siteResponse.id;
                        }

                        if (paramsService.coreClient) {
                            params.coreClient = JSON.parse(paramsService.coreClient).v;
                        }

                        return apiClient.get(user.getLink('actions'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    coreClientCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('coreclients')) {
                            return apiClient.get(rootResourceResponse.getLink('coreclients'), {'active': 'yes'}).then(function (response) {
                                return response || null;
                            });
                        }

                        return null;
                    },
                    canBulkCancel: function(userManager) {
                        return userManager.hasPermission('site_permission_bulk_cancel_actions');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ACTIONS" | translate }}'
                }
            });
    }

    function registerRoutesAdd() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.add', {
                abstract: true,
                url: '/add/{siteResource}?spillage',
                parent: config.baseRoute + '.manage-actions.list',
                views: getView('elogbooks-right@' + config.baseRoute, {
                    templateUrl: '/modules/common/actions/manage/add/action-form.html',
                    controller: 'CommonActionAddController',
                    controllerAs: 'vm'
                }),
                params: {
                    spillage: null
                },
                resolve: {
                    actionResponse: function()  {
                        return {};
                    },
                    spillageResponseLink: function($stateParams) {
                        if ($stateParams.spillage) {
                            return $stateParams.spillage.decode();
                        }

                        return null;
                    },
                    siteResponse: function(apiClient, selectedSiteResponse, $stateParams, siteResponse) {
                        if (siteResponse && siteResponse.id) {
                            return siteResponse;
                        } else if ($stateParams.siteResource) {
                            return apiClient.get($stateParams.siteResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            })
                        } else if (selectedSiteResponse) {
                            return selectedSiteResponse;
                        }

                        return;
                    },
                    config: function ($state, selectedSiteResponse) {
                        return {
                            siteDisabled: $state.current.name.indexOf('dashboard.user.sites') !== -1 || selectedSiteResponse
                        };
                    }
                },
                ncyBreadcrumb: getLabel('ACTION_ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.manage-actions.list.add.site', {
                url: '/site',
                parent: config.baseRoute + '.manage-actions.list.add',
                views: getView(config.baseView + '.list.add', {
                    templateUrl: '/modules/common/actions/manage/add/action-form-site.html',
                    controller: 'CommonActionAddSiteController',
                    controllerAs: '_' // Faked controllerAs so we can inherit from parent
                }),
                resolve: {
                    siteCollectionResponse: function(apiClient, user, config) {
                        if (config.siteDisabled) {
                            return;
                        }

                        return apiClient.get(user.getLink('sites'), { permission: ['site_permission_create_actions'], datagroup: 'search' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('ACTION_ADD_SITE')
            })
            .state(config.baseRoute + '.manage-actions.list.add.details', {
                url: '/details',
                parent: config.baseRoute + '.manage-actions.list.add.site',
                views: getView(config.baseView + '.list.add', {
                    templateUrl: '/modules/common/actions/manage/add/action-form-details.html',
                    controller: 'CommonActionAddDetailsController',
                    controllerAs: '_' // Faked controllerAs so we can inherit from parent
                }),
                resolve: {
                    actionTypeCollectionResponse: function(apiClient, rootResourceResponse, spillageResponseLink) {
                        var params = {
                            limit: 999999,
                            actionTypesForFilters: true,
                            active: true
                        };

                        if (spillageResponseLink) {
                            var raisedFromSpillageActionType = 5;
                            params.systemType = raisedFromSpillageActionType;
                        }

                        return apiClient.get(rootResourceResponse.getLink('actiontypes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, siteResponse) {
                        var parameters = {active: true, 'entityTypes': 'entity_type_all,entity_type_action'};
                        return apiClient.get(siteResponse.getLink('priorities'), parameters).then(function(response) {
                            if (response.priorities.length) {
                                return response || apiClient.noResourceFound();
                            } else {
                                return apiClient.get(rootResourceResponse.getLink('priorities'), parameters).then(function(response) {
                                    return response || apiClient.noResourceFound();
                                });
                            }
                        });
                    },
                    statutoryTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    locationCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    assetCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('assets')) {
                            return apiClient.get(siteResponse.getLink('assets'), { active:1}).then(function (response) {
                                return response;
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('ACTION_ADD_DETAILS')
            });
    }

    function registerRoutesDetails() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details', {
                abstract: true,
                url: '/{actionResource}',
                parent: config.baseRoute + '.manage-actions.list',
                views: getMultipleViews([
                    {
                        name: 'elogbooks-right@' + config.baseRoute,
                        value: {
                            templateUrl: '/modules/common/actions/manage/details/action-details.html',
                            controller: 'CommonActionDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    {
                        name: 'feed' + config.baseView +'.list.details',
                        value: {
                            templateUrl: '/modules/common/actions/manage/details/event/feed.html',
                            controller: 'CommonActionDetailsEventFeedController',
                            controllerAs: 'vm'
                        }
                    }
                ]),
                resolve: {
                    actionResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.actionResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function(apiClient, actionResponse) {
                        return apiClient.get(actionResponse.getLink('site')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    fileCollectionResponse: function(apiClient, actionResponse) {
                        return apiClient.get(actionResponse.getLink('files'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function(apiClient, actionResponse) {
                        return apiClient.get(actionResponse.getLink('events'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    messageCollectionResponse: function(apiClient, actionResponse) {
                        return apiClient.get(actionResponse.getLink('messages')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationMeridianDocTypesResponse: function() {
                        return null;
                    },
                    config: function(user, userManager, actionResponse) {
                        return {
                            canCreateJobs: userManager.hasPermission('site_permission_create_jobs') ||
                                (
                                    actionResponse._links.actiontype &&
                                    actionResponse._links.actiontype.allowServiceproviderAccess &&
                                    userManager.hasPermission('operative_permission_create_reactive_jobs')
                                ),
                            canCreateQuotes: userManager.hasPermission('site_permission_create_quotes') ||
                                (
                                    actionResponse._links.actiontype &&
                                    actionResponse._links.actiontype.allowServiceproviderAccess &&
                                    userManager.hasPermission('operative_permission_create_quotes')
                                )
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ $resolves().actionResponse.summary }}'
                }
            });
    }

    function registerRoutesDetailsJobs() {
        var jobParams = {
            jobsPage: "1",
            jobsLimit: "30",
            order: '-id',
            jobsOrder: '-id',
            jobSummary: null,
            jobStatus: {array: true},
            jobsType: null,
            statType: {array: true},
            jobPriority: {array: true},
            serviceProvider: null,
            jobId: null,
            alarmedAtStart: null,
            alarmedAtEnd: null,
            createdAtStart: null,
            createdAtEnd: null,
            completionDueStart: null,
            completionDueEnd: null,
            jobsReminderAtStart: null,
            jobsReminderAtEnd: null,
            jobsReminderAtBetween: null,
            jobsAttendanceDueStart: null,
            jobsAttendanceDueEnd: null,
            description: null,
            jobsOverdueAttendance: null,
            jobsOverdueCompletion: null,
            jobGroups: {array: true},
            operative: null,
            approver: {array: true},
            jobSiteRegion: {array: true},
            jobSiteGroups: {array: true},
            createdBy: null,
            embedded: 'jobs',
            completedAtStart: null,
            completedAtEnd: null,
            serviceProviderGroup: null,
            asset: null,
            subAsset: null,
            jobSpecificSpPriority: null,
            prosureStatusAtCompletion: {array: true},
            prosureStatusAtApproval: {array: true},
            poNumber: null,
        };

        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details.jobs', {
                url: '/jobs?' + Object.keys(jobParams).join('&'),
                parent: config.baseRoute + '.manage-actions.list.details',
                params: jobParams,
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/jobs/jobs-list.html',
                    controller: 'CommonJobsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    jobCollectionResponse: function($rootScope, $stateParams, jobStatuses, elbSettings) {
                        if (Array.isArray($stateParams.jobStatus)) {
                            $rootScope.jobStatusQuickFilter = $stateParams.jobStatus.length === 0 ? 'all' : 'filtered';
                        }

                        if (typeof($stateParams.jobStatus) === 'undefined' && $rootScope.jobStatusQuickFilter !== 'all') {
                            $stateParams.jobStatus = jobStatuses.getAll('nonfinal');
                        }

                        if (!elbSettings.getSetting('prosure_enabled').value) {
                            delete $stateParams.prosureStatusAtCompletion;
                            delete $stateParams.prosureStatusAtApproval;
                        }

                        return {
                            jobs: null,
                            pages: 0,
                            count: 0
                        }
                    },
                    config: function(actionResponse) {
                        return {
                            route: 'dashboard.user.sites.list.details.jobs.list.details.status',
                            resourceName: 'jobResource',
                            jobsLink: actionResponse.getLink('jobs'),
                            parameters: {
                                resource: actionResponse.getLink('site').encode()
                            },
                            ignoredFilters: [
                                'jobsPage',
                                'jobsLimit',
                                'jobsOrder',
                                'fileOrder',
                                'limit',
                                'childLocationActive',
                                'page',
                                'locationActive',
                                'resource',
                                'status',
                                'actionsPage',
                                'actionsLimit',
                                'actionsOrder',
                                'actionResource',
                                'order',
                                'embedded'
                            ]
                        };
                    },
                    statutoryTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, selectedSiteResponse, $stateParams) {
                        var priorityLinkParameters;

                        if ($stateParams.sites) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job', 'sites[]':$stateParams.sites};
                        } else if(selectedSiteResponse) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job', 'sites[]':selectedSiteResponse.id};
                        } else {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};
                        }

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    documentTypesCollectionResponse:  function() { return null; },
                    canBulkReassign: function() { return false; },
                    canBulkReassignOperative: function() { return false; },
                    canBulkCancel: function() { return false; }
                },
                ncyBreadcrumb: getLabel('JOBS')
            });
    }

    function registerRoutesDetailsQuoteRequests() {
        var quoteParams = {
            quoteRequestsPage: "1",
            quoteRequestsLimit: "30",
            id: null,
            quoteSummary: null,
            quoteStatus: {array: true},
            quotePriority: {array: true},
            serviceProviderName: null,
            overdue: null,
            alarmedAtStart: null,
            alarmedAtEnd: null,
            createdAtStart: null,
            createdAtEnd: null,
            completionDueStart: null,
            completionDueEnd: null,
            lastSubmittedAtStart: null,
            lastSubmittedAtEnd: null,
            reminderAtStart: null,
            reminderAtEnd: null,
            quoteDescription: null,
            quoteIncludeNeverSubmitted: null,
            operative: null,
            approver: {array: true},
            siteRegion: {array: true},
            siteGroups: {array: true},
            createdBy: null,
            embedded: 'quotes',
            serviceProviderGroup: null,
            budgetQuote: null,
            showBudgetQuoteFilter: null
        };

        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details.quote-requests', {
                url: '/quote-requests?' + Object.keys(quoteParams).join('&'),
                parent: config.baseRoute + '.manage-actions.list.details',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/quote-requests/quote-requests-list.html',
                    controller: 'CommonQuoteRequestsController',
                    controllerAs: 'vm'
                }),
                params: quoteParams,
                resolve: {
                    serviceProviderCollection: function() {
                        return null;
                    },
                    quoteRequestCollectionResponse: function(apiClient, actionResponse, $stateParams, quoteParamsService, user, selectedSiteResponse) {
                        var params = quoteParamsService.getQuoteParams($stateParams, user, selectedSiteResponse);
                        params = angular.extend({}, $stateParams, params);
                        delete params.status;

                        if ($stateParams.serviceProviderGroup) {
                            params.serviceProviderGroup = $stateParams.serviceProviderGroup;
                        }

                        return apiClient.get(actionResponse.getLink('quoterequests'), params).then(function(response) {
                            if (response.budgetQuoteFilter) {
                                $stateParams.showBudgetQuoteFilter = true;
                            }

                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(actionResponse) {
                        return {
                            route: 'dashboard.user.sites.list.details.quote-requests.list.details.quote.workflow',
                            parameters: {
                                resource: actionResponse.getLink('site').encode()
                            }
                        };
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, selectedSiteResponse, $stateParams) {
                        var priorityLinkParameters;

                        if ($stateParams.sites) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote', 'sites[]':$stateParams.sites};
                        } else if(selectedSiteResponse) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote', 'sites[]':selectedSiteResponse.id};
                        } else {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};
                        }

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse, siteResponse) {
                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (typeof siteResponse.id !== 'undefined') {
                            params.siteId = siteResponse.id;
                        } else if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTES')
            });
    }

    function registerRoutesDetailsWorkflow() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details.workflow', {
                url: '/workflow',
                parent: config.baseRoute + '.manage-actions.list.details',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/action-workflow.html',
                    controller: 'CommonActionDetailsWorkflowController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    scopeData: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('WORKFLOW')
            })
            .state(config.baseRoute + '.manage-actions.list.details.workflow.reject-job-paperwork', {
                url: '/rejectJobPaperwork',
                parent: config.baseRoute + '.manage-actions.list.details.workflow',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/_wrapper.html',
                    controller: 'ActionRejectJobPaperworkController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('ACTION_WORKFLOW_REJECT_JOB_PAPERWORK'),
            })
            .state(config.baseRoute + '.manage-actions.list.details.workflow.cancel', {
                url: '/cancel',
                parent: config.baseRoute + '.manage-actions.list.details.workflow',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/_wrapper.html',
                    controller: 'ActionCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('ACTION_WORKFLOW_CANCEL')
            })
            .state(config.baseRoute + '.manage-actions.list.details.workflow.reopen', {
                url: '/reopen',
                parent: config.baseRoute + '.manage-actions.list.details.workflow',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/_wrapper.html',
                    controller: 'ActionReopenController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('ACTION_WORKFLOW_REOPEN')
            })
            .state(config.baseRoute + '.manage-actions.list.details.workflow.complete', {
                url: '/complete',
                parent: config.baseRoute + '.manage-actions.list.details.workflow',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/_wrapper.html',
                    controller: 'ActionCompleteController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileCollectionResponse: function(apiClient, actionResponse) {
                        var params = {
                            order: 'id',
                            datagroup: 'details'
                        };

                        return apiClient.get(actionResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    fromJobResponse: function(apiClient, actionResponse) {
                        if (actionResponse.getLink('from-job')) {
                            return apiClient.get(actionResponse.getLink('from-job')).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return null;
                        }
                    },
                    integrationMeridianDocTypesResponse: function(apiClient, actionResponse, fromJobResponse) {
                        if (fromJobResponse && fromJobResponse.getLink('integration-doc-types')) {
                            return apiClient.get(fromJobResponse.getLink('integration-doc-types')).then(function(integrationDocTypesResponse) {
                                var integrationResponse = null;

                                angular.forEach(integrationDocTypesResponse.data, function(integration){
                                    //Only set meridian
                                    if(integration.integration === "meridian") {
                                        integrationResponse = integration;
                                    }
                                });

                                return integrationResponse;
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('ACTION_WORKFLOW_COMPLETE')
            })
            .state(config.baseRoute + '.manage-actions.list.details.workflow.reassign', {
                url: '/reassign',
                parent: config.baseRoute + '.manage-actions.list.details.workflow',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/actions/manage/details/workflow/_wrapper.html',
                    controller: 'ActionReassignController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteMembershipCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('memberships'), {permission: 'site_permission_view_actions', userActive: true}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    scopeData: function(siteMembershipCollectionResponse) {
                        return {
                            selectedUserModel: {
                                response: siteMembershipCollectionResponse,
                                link: siteMembershipCollectionResponse.getLink('self'),
                                linkParameters: {permission: 'site_permission_view_actions'},
                                required: true,
                                responseKeyPath: 'memberships',
                                itemHrefPath: '_links.user.href',
                                itemValuePath: '_links.user.title',
                            }
                        };
                    }
                },
                ncyBreadcrumb: getLabel('ACTION_WORKFLOW_REASSIGN')
            });
    }

    function registerRoutesDetailsSiteNotes() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details.site-notes', {
                url: '/site-notes',
                parent: config.baseRoute + '.manage-actions.list.details',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/site-notes/site-notes-list.html',
                    controller: 'CommonSiteNotesController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteNoteCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    }
                },
                ncyBreadcrumb: getLabel('SITE_NOTES'),
                scroll: false
            });
    }

    function registerRoutesDetailsFiles() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.list.details.files', {
                url: '/files',
                parent: config.baseRoute + '.manage-actions.list.details',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonActionsFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null
                },
                resolve: {
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), { limit: 30 }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            showStatutoryType: true
                        });
                    },
                    fileCollectionResponse: function(apiClient, actionResponse, $stateParams) {
                        var params = {
                            order: $stateParams.fileOrder,
                            datagroup: 'details'
                        };

                        return apiClient.get(actionResponse.getLink('files'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    },
                    fromJobResponse: function(apiClient, actionResponse) {
                        if (actionResponse.getLink('from-job')) {
                            var params = {
                                datagroup: 'details'
                            };

                            return apiClient.get(actionResponse.getLink('from-job'), params).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return null;
                        }
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: getLabel('FILES'),
                scroll: false
            })
            .state(config.baseRoute + '.manage-actions.list.details.files.add', {
                url: '/add',
                parent: config.baseRoute + '.manage-actions.list.details.files',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    config: function(config, fileCollectionResponse, siteResponse, actionResponse) {
                        return angular.extend(config, {
                            entitySite: siteResponse,
                            associatedEntity: actionResponse,
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showVisibility: true
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('FILES_ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.manage-actions.list.details.files.details', {
                url: '/{fileResource}',
                parent: config.baseRoute + '.manage-actions.list.details.files',
                views: getView('files' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/files/details/files-details.html',
                    controller: 'CommonFilesDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                },
                scroll: false
            })
            .state(config.baseRoute + '.manage-actions.list.details.files.details.edit', {
                url: '/edit',
                parent: config.baseRoute + '.manage-actions.list.details.files.details',
                views: getView('info' + config.baseView + '.list.details', {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: getLabel('FILE_EDIT')
            });
    }

    function registerRoutesImport() {
        $stateProvider
            .state(config.baseRoute + '.manage-actions.imports', {
                url: '/imports',
                parent:config.baseRoute + '.manage-actions',
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state(config.baseRoute + '.manage-actions.imports.list', {
                url: '/list?page&limit&order&type',
                parent: config.baseRoute + '.manage-actions.imports',
                views: getView(config.baseBookViewRight,{
                    templateUrl: '/modules/common/imports/list/list.html',
                    controller : 'CommonImportListController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    importsCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('imports'), $stateParams);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: 'user_action'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ACTION_IMPORTS" | translate }}'
                }
            })
            .state(config.baseRoute + '.manage-actions.imports.list.details', {
                url: '{importResource}/details?logsPage&logsLimit&logsOrder&logsType&logsMessage',
                parent: config.baseRoute + '.manage-actions.imports.list',
                views: getView('elogbooks-right@' + config.baseRoute,{
                    templateUrl: '/modules/common/imports/details/details.html',
                    controller : 'CommonImportDetailsController',
                    controllerAs: 'vm',
                }),
                params: {
                    logsPage: '1',
                    logsLimit: '30',
                    logsType: null,
                    logsMessage: null
                },
                resolve: {
                    importResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.importResource.decode());
                    },
                    importLogsCollectionResponse: function (apiClient, importResponse, $stateParams) {
                        var params = {
                            page: $stateParams.logsPage,
                            limit: $stateParams.logsLimit,
                            type: $stateParams.logsType,
                            message: $stateParams.logsMessage
                        };
                        return apiClient.get(importResponse.getLink('logs'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_DETAILS_LIST" | translate }}'
                }
            })
            .state(config.baseRoute + '.manage-actions.imports.list.add', {
                url: '/add',
                parent: config.baseRoute + '.manage-actions.imports.list',
                views: getView('elogbooks-right@' + config.baseRoute,{
                    templateUrl: '/modules/common/imports/add/import-add.html',
                    controller: 'CommonAddImportController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    sectionResource: function() {
                        return {
                            type: 'user_action',
                            name: 'user_action'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"IMPORT_ADD" | translate }}'
                }
            });
    };

    return this;
}
