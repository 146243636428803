angular.module('elogbooksDirectives').directive('elogbooksLocation', ['lodash','messenger', '$uibModal', function (lodash, messenger, $uibModal) {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            location: '=',
            locations: '=',
            returnObject: '@',
            isRequired: '=',
            parentAdd: '=?',
            hideLocationPath: '=',
            key: '='
        },
        templateUrl: '/modules/directives/form-elements/location/location.html',
        link: function(scope, element, attrs) {
            // Events required
            scope.raiseEvents = lodash.has(attrs, 'raiseEvents');
        },
        controller: function ($scope, $sce, apiClient, $uibModal) {
            $scope.loading = false;
            $scope.loadPath = false;
            $scope.onSelect = onSelect;
            $scope.onSearch = onSearch;
            $scope.onLoadMore = onLoadMore;
            $scope.onClear = onClear;
            $scope.generateSelectedLocationRoute = generateSelectedLocationRoute;
            $scope.createNewLocationModal = createNewLocationModal;
            $scope.parentAdd = $scope.parentAdd === false ? false : true;

            generateSelectedLocationRoute();

            if ($scope.location && $scope.location.href && typeof $scope.location.path === 'undefined') {
                apiClient.get($scope.location.href).then(function (response) {
                    $scope.location = response;
                    //make location path directive to wait for value
                    $scope.loadPath = true;
                });
            }

            if ($scope.model) {
                const parentLocation = lodash.find($scope.locations.locations, function (item) {
                    return item._links.self.href === $scope.model.href;
                });

                if (parentLocation) {
                    $scope.locationFormFields[0].selected = parentLocation;
                    $scope.location = parentLocation;
                    onSelect(parentLocation, 0, undefined);
                } else {
                    apiClient.get($scope.model.href).then(function (response) {
                        if (response.path.length > 1) {
                            angular.forEach(response.path, function (path, index) {
                                if (index > 0) {
                                    $scope.locationFormFields.push(createFormField([path], $scope.location));
                                }

                                $scope.locationFormFields[index].selected = path;
                                $scope.location = path;
                                const child = index > 0 && response.path.length < index ? response.path[index+1] : undefined;
                                onSelect(path, index, child);
                            });
                        }
                    });
                }
            }

            $scope.$watch('locations', function($newVal) {
                $scope.locationFormFields = [];
                $scope.locationFormFields.push(createFormField($newVal, $scope.location));
            }, true);

            function onLoadMore($event, index) {
                if ($scope.loading) {
                    return;
                }

                $scope.locationFormFields[index].noMore = false;

                if ($scope.locationFormFields[index].page === $scope.locationFormFields[index].pages) {
                    messenger.error('NO_MORE_RESULTS');
                }

                if ($scope.locationFormFields[index].page < $scope.locationFormFields[index].pages) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    $scope.loading = true;

                    apiClient.get($scope.locationFormFields[index]._links.next.href, this.linkParameters).then(function (response) {
                        $scope.locationFormFields[index].locations = $scope.locationFormFields[index].locations.concat(response.locations);
                        $scope.locationFormFields[index].response = response;
                        $scope.locationFormFields[index].page = response.page;
                        $scope.locationFormFields[index].pages = response.pages;
                        $scope.locationFormFields[index]._links = response._links;
                        $scope.locationFormFields[index].noMore = $scope.locationFormFields[index].page === $scope.locationFormFields[index].pages;
                        $scope.loading = false;
                    });
                }
            }

            function onSelect($item, $index, $child) {
                $scope.locationFormFields.splice($index + 1, $scope.locationFormFields.length);

                if ($scope.returnObject) {
                    $scope.model = { href: $item };
                } else {
                    $scope.model = { href: $item._links.self.href };
                    $scope.locationFormField = {
                        selected: $item._links.self.href
                    };
                }

                if ($scope.key >= 0) {
                    $scope.model.key = $scope.key;
                }

                if ($item._links.children !== undefined) {
                    apiClient.get($item._links.children.href).then(function (response) {
                        if (response.count > 0) {
                            $scope.locationFormFields.push(createFormField(response, $child));
                        }
                    });
                }
                if ($scope.raiseEvents) {
                    $scope.$emit('location:select:click', $scope.model);
                }
            }

            function onSearch(keyword, index) {
                // hide the load-more button whilst searching
                $scope.locationFormFields[index].noMore = true;

                if ($scope.locationFormFields[index].response
                    && $scope.locationFormFields[index].response.getLink('self')
                    && !$scope.loading
                ) {
                    $scope.loading = true;

                    // Async function needs to access object properties from global scope
                    apiClient.get($scope.locationFormFields[index].response.getLink('self'), {name: keyword, page:1}).then(function (response) {
                        $scope.loading = false;
                        $scope.keyword = keyword;
                        $scope.locationFormFields[index].locations = response.locations;
                        $scope.locationFormFields[index].response = response;
                        $scope.locationFormFields[index].page = response.page;
                        $scope.locationFormFields[index].pages = response.pages;
                        $scope.locationFormFields[index].response = response;
                        $scope.locationFormFields[index].noMore = $scope.locationFormFields[index].page === $scope.locationFormFields[index].pages;
                    });
                } else {
                    $scope.loading = false;
                }
            }

            function onClear($index) {
                $scope.locationFormFields.splice($index + 1, $scope.locationFormFields.length);
                $scope.locationFormFields[$index].selected = null;
                if ($scope.locationFormFields[$index - 1]) {
                    $item = $scope.locationFormFields[$index - 1];

                    if ($scope.returnObject) {
                        $scope.model.href = $item.selected;
                    } else {
                        $scope.model.href = $item.selected._links.self.href;
                    }
                } else {
                    delete $scope.model;
                }

                if ($scope.raiseEvents) {
                    $scope.$emit('location:select:clear', $index);
                }
            }

            function createFormField($locations, $location) {

                if ($location && typeof $location.title !== 'undefined') {
                    $location.name = $location.title;
                }

                var noMore = true;

                if (typeof $locations !== 'undefined' && $locations.hasOwnProperty('locations')) {

                    if ($locations.page !== $locations.pages) {
                        noMore = false;
                    }

                    return {
                        selected: $location,
                        locations: $locations.locations,
                        limit: 30,
                        page: $locations.page,
                        pages: $locations.pages,
                        _links: $locations._links,
                        response: $locations,
                        noMore: noMore
                    };
                }

                return {
                    selected: $location,
                    locations: $locations,
                    noMore: noMore
                };

            }

            /**
             * Generate the slash delimited route to display above the selected location
             */
            function generateSelectedLocationRoute() {
                $scope.locationFormFields = [];
                $scope.locationFormFields.push(createFormField($scope.locations, $scope.location));
            }

            function createNewLocationModal() {
                // Determine youngest child that has an option selected
                var youngestChildIndex = null;
                youngestChildIndex = lodash.findLastIndex($scope.locationFormFields, function(n) {
                    return n.selected !== undefined && n.selected !== null;
                });

                var parentLocation = null;
                if (youngestChildIndex >= 0 && $scope.locationFormFields[youngestChildIndex].selected) {
                    parentLocation = $scope.locationFormFields[youngestChildIndex].selected;
                }

                var modalInstance = $uibModal.open({
                                    templateUrl: '/modules/common/modals/create-location/create-location-modal.html',
                                    controller: 'CreateLocationModalController',
                                    controllerAs: 'vm',
                                    resolve: {
                                        parentLocation: function() {
                                            return parentLocation;
                                        },
                                        createLink: function() {
                                            return $scope.locationFormFields[0]._links.create.href;
                                        }
                                    }
                });

                modalInstance.result.then(function (item) {
                    if (parentLocation === null) {
                        $scope.locationFormFields = [];
                        $scope.locationFormFields.push(createFormField($scope.locations, item));
                        $scope.model = { href: item.getLink('self') };
                        onSelect($scope.model, 0);
                    } else {
                        onSelect(parentLocation, youngestChildIndex, item);
                        $scope.model = { href: item.getLink('self') };
                    }

                });
            }
        }
    };
}]);
